<template>
  <div >
    <iframe id="iframe" :src="src" style=" width:100%;"  frameborder="0"></iframe>
    <v-row>
      <v-dialog v-model="dialog" hide-overlay persistent width="300">
        <v-card color="primary" dark class="spacing-playground pa-3">
          <v-card-text>
            Đang xử lý, vui lòng chờ!
            <p></p>
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

  </div>
</template>
<script>
export default {
  data: () => ({
    src: "",
    dialog: false,
  }),
  mounted() {
    let email = localStorage.getItem("Email");
     // let src = `https://internal.vietmap.vn/app/cortex/dashboard-63ae5fa115321f14060c733c?Email=${email}`; // PROD
     if(this.$route.name == 'orderdetail'){
       let orderCode =  this.$route.params.orderCode
       //let src = `https://appsmith.vietmap.vn/app/dx-new-cortex/orderdetail-64d99b6fa95b1a2710d9729c?branch=master&orderCode=${orderCode}&Email=${email}`; // Order Detai (TESTING)
       let src = `https://appsmith.vietmap.vn/app/cortex/orderdetail-64b0cbd8cdb08e7c8778da09?branch=master&&orderCode=${orderCode}&Email=${email}`; // Order Detai (STAGING)
       this.src = src;
       window.history.pushState(null, null, "#/")  
      // console.log(this.$route.params.orderId)
     }else  if(this.$route.name =='receiptdetail'){
      let receiptcode = this.$route.params.receiptcode
      //let src = `https://appsmith.vietmap.vn/app/dx-new-cortex/detail-receipt-64d99b6fa95b1a2710d972e7?branch=master&receiptCode=${receiptcode}&Email=${email}`; // Receipt Detail (TESTING)
      let src = `https://appsmith.vietmap.vn/app/cortex/detail-receipt-64b0cbd8cdb08e7c8778da55?branch=master&receiptCode=${receiptcode}&Email=${email}`; // Receipt Detail (STAGING)
      this.src = src;
      window.history.pushState(null, null, "#/")
      //console.log('Receipt Detail')
   }else {
      //let src = `https://appsmith.vietmap.vn/app/dx-new-cortex/dashboard-64d99b6fa95b1a2710d972a1?branch=master&Email=${email}`; // (TESTING)
      let src = `https://appsmith.vietmap.vn/app/cortex/dashboard-64b0cbd8cdb08e7c8778da32?branch=master&Email=${email}`; // (STAGING)
      this.src = src;
  }    
  },
};
</script>
<style scoped>
iframe {
  display: block;
  border: none;
  height: calc(100vh);
  width: 100vw;
  /* margin-top: -5px; */
  z-index: 998;
  overflow: hidden;
}
</style>